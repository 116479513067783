
import { defineComponent } from "vue";
import { client } from '@/client';
import { ElMessage } from "element-plus";
import { FriendGroupsData } from "@/shared/types/FriendsData";
export default defineComponent({
    name: "group-edit",
    data: () => {
      return {
        params: {} as FriendGroupsData,
  
        paramsRules: {
          //校验规则
          groupName: [
            { required: true, message: "请输入标签名称", trigger: "blur" },
            {
              min: 2,
              max: 8,
              message: "名称长度在 2 到 8 个字符之间",
              trigger: "blur",
            },
          ],
        },
      };
    },
    watch: {
      "params.groupName": function (newv, oldv) {
        console.error(newv,oldv);
      },
    },
    async created() {
      let id = this.$route.params.id as string;
      this.params.id = parseInt(id);
      await this.detail();
    },
    methods: {
      // 文章详情
      async detail() {
          let res = await client.callApi("group/Destail",{id:this.params.id});
          if (res.isSucc) {
            this.params = {
                ...res.res
            }
          }
      },
      //新增
      async update() {
        let res = await client.callApi("group/Update",this.params);
        if (res.isSucc) {
            ElMessage({
                message: "更新成功^_^",
                type: "success",
            });
            this.$router.go(-1);
        }else{
            ElMessage({
                message: res.err.message,
                type: "error",
            });
        }
      },
      submit(formName: string) {
        let refs:any = this.$refs[formName];
  
        refs.validate((valid: any) => {
          if (valid) {
              this.update();
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
    },
  });
  